//
// Skin: Green
// Mode: Light
//

$green:         #005699 !default;
$primary:       $green !default;
$dark: 			$primary !default;
$secondary:     #E9B82B !default;	
$neutral:       #04aebf !default;	

$primary-light-60: lighten($primary, 60%) !default;

$list-group-action-hover-color: $secondary !default;