// User styles
//
// This is the place where you should place all additional styles and imports.
// This will allow you to take advantage of future updates with lower time consumed on the upgrade process.

body {
	background: white !important;
}
.background-primary {
	background: $primary !important;
	color: white;
}
.bg-gradient-dark {
	background: $primary !important;
}
#navbar-logo {
	height: 75px;
}
.navbar {
	background: linear-gradient(to right, rgba(255,255,255,0.8) 25%, rgba(0, 86, 153,0.8) 75%) !important;
}
.container-background {
	opacity: 0.5;
}
.nav-link {
	color: #F3F4F5 !important;
}
.nav-link:hover {
	color: $secondary !important;
}
.list-group-item>.media {
	margin-left: 15px;
}
.list-group-item:hover {
	background-color: #F9F9F9 !important;
}
.list-group-item:hover .media-body>a {
	color: $secondary !important;
}
.h1, .h4, h3, h5{
	color: $primary;
}
blockquote h5 {
	color: $primary !important;
}
article .h4, .text-danger, .light-primary {
	color: $secondary !important;
}
.badge-warning {
	background: $secondary !important;
}
.list-unstyled {
	margin: 0 !important;
}
.footer .list-unstyled li a {
	padding: 0;
}
.header-footer {
	line-height: 1.7;
}
#background-video {
	width: 100vw;
	height: 100vh;
	object-fit: cover;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 0 !important;
}
#main-page-img {
	height: 700px;
}
#main-page-header {
	background: transparent !important;
	background-color: transparent !important;
}


.text-secondary {
	color: $secondary !important;
}
.list-group-item figure {
	padding-left: 10px;
}
.no-arrow::after {
	content: "" !important;
}
.footer-menu {
	place-content: center;
}
.inactiveLink {
	pointer-events: none;
	cursor: default;
}
.background-0 {
	background: none !important;
}
.fill-section-light {
	fill: $primary-light-60 !important; 
}
.container-img-contact {
    display: flex;
    overflow: hidden;
}
.container-img-contact img {
    flex-shrink: 0;
	height: 255px;
	width: 255px;
}
.container-img-surgery {
    display: flex;
    overflow: hidden;
}
.container-img-surgery img {
    flex-shrink: 1;
	height: 262px;
	width: 262px;
}
.card-hover:hover h5 {
	color: $secondary !important;
}
.dropdown-item:hover {
	color: $secondary !important;
}
.text-center-certif {
	display: table;
}
.text-center-certif span {
	display: table-cell;
	vertical-align: middle;
	align-items: left;
}